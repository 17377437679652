<template lang="pug">
div
  b-button(
    type="button",
    variant="warning",
    size="sm"
    class="mr-1"
    @click="activateFileSelector"
  )
    | {{ $t('message.upload_evidence') }}
  input(
    type="file",
    ref="fileSelector"
    style="display: none",
    :accept="inputFileTypes",
    multiple
    @change="changeFileSelected"
  )
  small(v-if="documentFile && documentFile.length")
    | {{ documentFile.length === 1 ? documentFile[0].name : `${documentFile.length} ${$t('evidences')}` }}
</template>
    
<script>
    import { ref } from '@vue/composition-api/dist/vue-composition-api'
    import { inputFileTypes } from '@/constants'
    
    export default {
      name: 'Form',
    
      components: {
      },
        
      setup(props, { emit }) {
        const form = ref(null)
        const fileSelector = ref(null)
        const documentFile = ref([])
    
        const activateFileSelector = () => {
          fileSelector.value.click()
        }
    
        const changeFileSelected = (e) => {
          documentFile.value = Array.from(e.target.files)
          emit('fileToUpload', documentFile.value)
        }
        
        return {
          form,
          fileSelector,
          documentFile,
          activateFileSelector,
          changeFileSelected,
          inputFileTypes,
        }
      },
    }
</script>
    
<style scoped>

</style>
    