<template>
  <div>
    <b-sidebar
      id="sidebar-task-handler"
      sidebar-class="sidebar-lg"
      :visible="isTaskHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-task-handler-sidebar-active', val)"
      @hidden="clearForm(); resetVariables()"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0">
            {{ taskIndex !== -1 || behaviourHasImprovement ? $t('message.improvement') : $t('add_improvement') }}
          </h5>
          <div>
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer #default="{ handleSubmit }" ref="refFormObserver">

          <!-- Form -->
          <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">

            <!-- Title -->
            <validation-provider #default="validationContext" :name="$t('label.Title')" rules="required">
              <b-form-group :label="$t('label.Title')" label-for="task-title">
                <b-form-input
                  id="task-title"
                  v-model="taskLocal.note"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  :placeholder="$t('placeholder.improvement_title')"
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Description -->
            <b-form-group :label="$t('label.description')" label-for="description">
              <b-form-textarea
                v-model="taskLocal.description"
                rows="2"
                :placeholder="$t('message.write_a_description')"
              />
            </b-form-group>

            <!-- Upload Document -->
            <b-form-group :label="$t('label.document')" label-for="document">
              <b-button
                class="mr-1"
                type="button"
                variant="info"
                @click="activateFileSelector"
              >
                {{ $t('message.upload') }}
              </b-button>
              <input
                type="file"
                ref="fileSelector"
                style="display: none"
                :accept="inputFileTypes"
                multiple
                @change="changeFileSelected"
              />
              <small v-if="documentFile && documentFile.length">
                {{ documentFile.length === 1 ? documentFile[0].name : `${documentFile.length} ${$t('documents')}` }}
              </small>
            </b-form-group>

            <!-- Associated Cases -->
            <b-form-group :label="$t('label.associated_cases')" label-for="associated_case">
              <v-select
                v-model="taskLocal.associatedCases"
                label="title"
                :multiple="true"
                :options="improvements"
                :reduce="improvement => improvement.value"
                :placeholder="$t('placeholder.associated_cases')"
              >
                <!-- <template #footer>
                  <b-button
                    v-show="taskLocal.associatedCases"
                    variant="warning"
                    size="sm"
                    :style="{ marginTop: '7px' }"
                    @click="openCase(taskLocal.associatedCase)"
                  >
                    {{ $t('message.see_case') }}
                  </b-button>
                </template> -->
              </v-select>
            </b-form-group>

            <!-- Operational Areas -->
            <b-form-group :label="$t('label.operationalAreas')" label-for="operational-areas">
              <v-select
                v-model="taskLocal.areas"
                label="title"
                :multiple="true"
                :options="locations"
                :reduce="location => location.value"
                :placeholder="$t('placeholder.operationalAreas')"
              />
            </b-form-group>

            <!-- Registration Responsible -->
            <validation-provider #default="validationContext" :name="$t('label.registration_responsible')" rules="required">
              <b-form-group :label="$t('label.registration_responsible')" label-for="registration-responsible">
                <v-select
                  v-model="taskLocal.assignee"
                  label="title"
                  :options="workersSupervised"
                  :reduce="(workersSupervised) => workersSupervised.value"
                  :placeholder="$t('placeholder.registration_responsible')"
                >
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Due Date Registration-->
            <validation-provider #default="validationContext" :name="$t('label.due_date_registration')" rules="required">
              <b-form-group :label="$t('label.due_date_registration')" label-for="due-date-registration">
                <flat-pickr
                  v-model="taskLocal.dueDate"
                  :config="flatPickrConfig"
                  class="form-control"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Proposal Responsible -->
            <b-form-group :label="$t('label.proposal_responsible')" label-for="proposal-responsible">
              <v-select
                v-model="taskLocal.assignee"
                label="title"
                :options="workersSupervised"
                :disabled="true"
                :reduce="(workersSupervised) => workersSupervised.value"
                :placeholder="$t('placeholder.proposal_responsible')"
              />
            </b-form-group>

            <!-- Due Date Proposal -->
            <b-form-group :label="$t('label.due_date_proposal')" label-for="due-date-proposal">
              <flat-pickr
                v-model="taskLocal.proposalDueDate"
                :config="flatPickrConfig"
                class="form-control"
              />
            </b-form-group>

            <!-- Approval Responsible -->
            <b-form-group :label="$t('label.approval_responsible')" label-for="approval-responsible">
              <v-select
                v-model="taskLocal.approvalResponsible"
                label="title"
                :options="workersSupervised"
                :reduce="(workersSupervised) => workersSupervised.value"
                :placeholder="$t('placeholder.approval_responsible')"
              />
            </b-form-group>

            <!-- Due Date Approval -->
            <b-form-group :label="$t('label.due_date_approval')" label-for="due-date-approval">
              <flat-pickr
                v-model="taskLocal.approvalDueDate"
                :config="flatPickrConfig"
                class="form-control"
              />
            </b-form-group>

            <!-- Verification Responsible -->
            <b-form-group :label="$t('label.verification_responsible')" label-for="verification-responsible">
              <v-select
                v-model="taskLocal.verificationResponsible"
                label="title"
                :options="workersSupervised"
                :reduce="(workersSupervised) => workersSupervised.value"
                :placeholder="$t('placeholder.verification_responsible')"
              />
            </b-form-group>

            <!-- Due Date Verification -->
            <b-form-group :label="$t('label.due_date_verification')" label-for="due-date-verification">
              <flat-pickr
                v-model="taskLocal.verificationDueDate"
                :config="flatPickrConfig"
                class="form-control"
              />
            </b-form-group>

            <!-- Activity -->
            <b-form-group
              v-if="taskLocal.activity"
              :label="$t('label.activity')"
            >
              <b-form-input
                v-model="taskLocal.activity"
                disabled
              />
            </b-form-group>

            <!-- Behaviour -->
            <b-form-group
              v-if="taskLocal.behaviour"
              :label="$t('label.behaviour')"
            >
              <b-form-input
                v-model="behaviourReformatted"
                disabled
              />
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ taskIndex !== -1 || behaviourHasImprovement ? $t('message.update') : $t('message.add') }}
              </b-button>
              <b-button
                v-if="taskIndex !== -1 || behaviourHasImprovement"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-danger"
                @click="$emit('remove-task', taskIndex)"
              >
                {{ $t('message.delete') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BButton, BFormInvalidFeedback, BModal, VBModal, BFormTextarea } from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs, onMounted, ref } from '@vue/composition-api'
import useTaskHandler from './useTaskHandler'
import useCommon from '@/views/organization/useCommon'
import store from '@/store'
import { useRouter } from '@core/utils/utils'
import { flatPickrConfig, inputFileTypes } from '@/constants'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BModal,
    BFormTextarea,

    // 3rd party packages
    vSelect,
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    task: {
      type: Object,
      required: true,
    },
    taskIndex: {
      type: Number,
      default: -1,
    },
    behaviourHasImprovement: {
      type: Boolean,
      default: false,
    },
    clearTaskData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const { taskLocal, resetTaskLocal, onSubmit, documentFile, behaviourReformatted } = useTaskHandler(toRefs(props), emit)
    const { refFormObserver, getValidationState, resetForm, clearForm } = formValidation(resetTaskLocal, props.clearTaskData)
    const { router } = useRouter()
    const { getWorkersSupervisedForDropDown, workersSupervised, getImprovementsForDropDown, improvements, getLocationsForDropDown, locations } = useCommon()

    const fileSelector = ref(null)

    const resetVariables = () => {
      // Set toggle task handler sidebar in store to false
      store.commit('app-todo/SET_TOGGLE_TASK_HANDLER_SIDEBAR', false)
    }

    const activateFileSelector = () => {
      fileSelector.value.click()
    }

    const changeFileSelected = (e) => {
      documentFile.value = Array.from(e.target.files)
    }

    const openCase = (id) => {
      const routeData = router.resolve({ name: 'organization-case-view', params: { id } });
      window.open(routeData.href, '_blank');
    }

    onMounted(() => {
      getWorkersSupervisedForDropDown()
      getImprovementsForDropDown()
      getLocationsForDropDown()
    })

    return {
      // Add New
      taskLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Dropdowns
      workersSupervised,
      improvements,
      locations,

      // Others
      resetVariables,
      fileSelector,
      activateFileSelector,
      changeFileSelected,
      documentFile,
      router,
      openCase,
      behaviourReformatted,
      flatPickrConfig,
      inputFileTypes,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
</style>
