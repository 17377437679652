import { ref, watch, computed } from '@vue/composition-api'

export default function useTaskHandler(props, emit) {
  const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const documentFile = ref([])
  const behaviourReformatted = computed(() => taskLocal.value.behaviour?.name?.replace(/<[^>]*>/g, ''))

  const resetTaskLocal = () => {
    taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
    documentFile.value = props.task.value.documentFile || []
  }

  watch(props.task, () => {
    resetTaskLocal()
  })

  const onSubmit = () => {
    const taskData = JSON.parse(JSON.stringify(taskLocal))
    if (documentFile.value?.length) taskData.value.documentFile = documentFile.value

    if (props.taskIndex.value !== -1 || props.behaviourHasImprovement.value) {
      emit('update-task', taskData.value, props.taskIndex.value)
    } else {
      emit('add-task', taskData.value)
    }

    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  return {
    taskLocal,
    resetTaskLocal,
    onSubmit,
    documentFile,
    behaviourReformatted
  }
}
